<template>
  <div>
    <!-- banner -->
    <swiper
      ref="banner"
      class="banner swiper-container"
      :options="bannerSwiperOptions"
    >
      <swiper-slide
        class="swiper-slide"
        :style="{ backgroundImage: `url(${banner})` }"
      >
        <div class="banner-title">移动互联网灵活用工平台</div>
        <button class="button ghost banner-button">
          <i class="icon iconfont icon-xiaochengxu" />进入小程序
          <i class="icon iconfont icon-xiala1"></i>
          <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
        </button>
      </swiper-slide>
      <swiper-slide
        class="swiper-slide"
        :style="{ backgroundImage: `url(${banner})` }"
      >
        <div class="banner-title">移动互联网灵活用工平台</div>
        <button class="button ghost banner-button">
          <i class="icon iconfont icon-xiaochengxu" />进入小程序
          <i class="icon iconfont icon-xiala1"></i>
          <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
        </button>
      </swiper-slide>
      <swiper-slide
        class="swiper-slide"
        :style="{ backgroundImage: `url(${banner})` }"
      >
        <div class="banner-title">移动互联网灵活用工平台</div>
        <button class="button ghost banner-button">
          <i class="icon iconfont icon-xiaochengxu" />进入小程序
          <i class="icon iconfont icon-xiala1"></i>
          <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
        </button>
      </swiper-slide>
      <!-- Add Pagination -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- banner end -->

    <!-- 产品优势 -->
    <div class="title fade-in">产品优势</div>
    <div class="sub-title fade-in">
      促进企业与劳动者双向服务，搭建用工就业高效桥梁，从此“招聘”、“找工作”不再难
<!--      积极拓与生态伙伴合作，赋能生态伙伴，打造更完整、更强大的配送生态-->
    </div>
    <div class="pro-card fade-in">
      <div class="pro-item">
        <img src="../assets/home/icon1.png" alt="" />
        <div class="pro-title">海量任务</div>
        <div class="pro-sub-title">
          任务大厅与自定义接单，汇聚多类型订单
<!--          新灵活就业平台拥有千万用户，实时发布任务。-->
        </div>
      </div>
      <div class="pro-item">
        <img src="../assets/home/icon5.png" alt="" />
        <div class="pro-title">快捷提现</div>
        <div class="pro-sub-title">
          高效审核，支持多种提现付款方式
        </div>
      </div>
      <div class="pro-item">
        <img src="../assets/home/icon6.png" alt="" />
        <div class="pro-title">全方位保障</div>
        <div class="pro-sub-title">
          针对性保障方案，客服全程管理，保障安全与权益
        </div>
      </div>

      <div class="pro-item">
        <img src="../assets/home/icon2.png" alt="" />
        <div class="pro-title">专业运营</div>
        <div class="pro-sub-title">
          配备专业运营团队，行业经验丰富
        </div>
      </div>

      <div class="pro-item">
        <img src="../assets/home/icon3.png" alt="" />
        <div class="pro-title">品质审核</div>
        <div class="pro-sub-title">
          对人员资质、任务完成情况进行周密审核与管控
        </div>
      </div>
      <div class="pro-item">
        <img src="../assets/home/icon4.png" alt="" />
        <div class="pro-title">精准对接</div>
        <div class="pro-sub-title">平台规范化运营，快速实现系统对接</div>
      </div>

<!--      <div class="pro-item">-->
<!--        <img src="../assets/home/icon6.png" alt="" />-->
<!--        <div class="pro-title">产品架构完善</div>-->
<!--        <div class="pro-sub-title">经数千万用户数亿次计算，实战验证。</div>-->
<!--      </div>-->
    </div>
    <!-- 产品优势 end -->

    <!-- 视屏 -->
    <div class="video fade-in">
      <i v-show="!playing" class="icon iconfont icon-bofang" @click="play"></i>

      <video
        ref="video"
        v-show="playing"
        class="player"
        src="http://www.w3school.com.cn/example/html5/mov_bbb.mp4"
        preload="true"
        controls="true"
        x5-playsinline="true"
        playsInline="true"
        webkit-playsinline="true"
        muted="true"
      ></video>
      <img
        class="close"
        src="../assets/home/close.png"
        v-show="playing"
        alt=""
        @click="close"
      />

      <div class="bg"></div>
    </div>
    <!-- 视屏end -->

    <!-- 行业 -->
    <div class="title fade-in">全行业覆盖</div>
    <div class="sub-title fade-in">合作客户覆盖全行业，任务秒发秒接</div>

    <div class="case fade-in">
      <div class="sider-bar">
        <div class="line"></div>
        <div :class="{ active: typeIndex === 0 }" @click="typeIndex = 0">
          <i class="icon iconfont icon-yifahuo"></i>外卖配送
        </div>
        <div>
          <i class="icon iconfont icon-meishiyinpin"></i>美食饮品
        </div>
        <div  @click="typeIndex = 1">
          <i class="icon iconfont icon-shengwuyiyao"></i>生物医药
        </div>
        <div  @click="typeIndex = 2">
          <i class="icon iconfont icon-falv"></i>法律法规
        </div>
        <div  @click="typeIndex = 3">
          <i class="icon iconfont icon-yingxiaotuiguang"></i>营销推广
        </div>
        <div  @click="typeIndex = 4">
          <i class="icon iconfont icon-pinpaisheji"></i>品牌设计
        </div>
        <div  @click="typeIndex = 5">
          <i class="icon iconfont icon-youxifuwu"></i>游戏服务
        </div>
      </div>
      <div class="case-context fade-in">
        <swiper
          class="case-swiper swiper-container"
          :options="caseSwiperOptions"
        >
          <swiper-slide class="swiper-slide">
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
            <div class="line" />
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
            <div class="line" />
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
            <div class="line" />
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
            <div class="line" />
            <div>
              <div class="header">
                <img
                  class="icon-complete"
                  src="../assets/home/icon_complete.png"
                  alt=""
                />
                <div>
                  <p class="case-title">完成快递包裹派送</p>
                  <p class="case-sub-title">
                    预计完成时间：2020年11月3日 - 2020年11月8日
                  </p>
                </div>
              </div>
              <div class="context">
                <div>
                  <span class="label">本单收入：</span
                  ><span class="money">￥600.00</span>
                </div>
                <div>
                  <span class="label">任务编号：</span
                  ><span class="no">872689</span>
                </div>
                <div>
                  <span class="label">任务详情：</span>
                  <span class="desc"
                    >完成快递包裹派送余额80件，请按时送往包裹指定地点，具体业务量以实际操作结果为准。</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
          <!-- Add Pagination -->
          <div
            class="case-swiper-pagination swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
    <button class="button primary round mt60 mb60 hover-shdow" @click="taskView">任务大厅</button>
    <!-- 行业 end-->
    <!-- 最新资讯 -->
    <div class="news">
      <div class="title fade-in">最新资讯</div>
      <div class="sub-title fade-in">
        实时资讯更新，链接行业动态、洞悉行业利好
      </div>
      <div class="context fade-in">
        <div class="card">
          <img src="../assets/home/new1.png" alt="" />
          <div>
            <div class="card-title">有什么兼职可以在家做？</div>
            <div class="card-sub-title">2022-08-16 09:11:52</div>
            <div class="card-desc">
              <p>在家适合做什么工作?一些家庭妇女，在校学生，甚至残疾人，都希望利用业余时间在家工作，哪些工作，哪些兼职适合在家做呢?在家做什么
                兼职靠谱又能赚钱?小编为大家整理一些适合在家做的工作推荐，参考一下在家做兼职哪个靠谱。</p>
              <p>适合在家做的十种兼职</p>
              <p>1、数据录入</p>
              <p>这是一个大行业，几乎所有人都可以做。需要的仅仅是一台能上网的电脑。大多数情况下，工作内容非常简单，但比较单调，并需要每天拿出
                时间完成任务。但好处是不需要太动脑子就可以轻易获得一些收入。</p>
              <p>2、美容沙龙</p>
              <p>这也是大多数女性可以选择的兼职工作。如果房子足够大，完全可以在家里开办一间美容沙龙。然而，这项工作需要有一定技能和经验，并且
                需要在设备和美容产品方面投入一些资金。</p>
              <p>3、教学辅导</p>
              <p>这也是最流行的在家工作方式。它不需要投资和太多广告宣传。通过公众口口相传就足够了。</p>
              <p>4、保姆</p>
              <p>这也是最流行的夏季兼职工作，尤其适合年轻人做。很多家长需要在周末找到兼职保姆。对于喜欢孩子的女大学生是很不错的选择。</p>
              <p>5、网络工作</p>
              <p>利用互联网在家做生意和兼职工作是当代许多大学生的选择。但你需要一台能上网的电脑，并要掌握一定计算机知识。从网络营销到软件开
                发，可以选择的项目有很多。网络兼职还是很多的，网上搜索网络兼职，一堆网络兼职工作可以做，譬如刷单，留言，投稿写文章，这些都适合一
                些人在家做。</p>
              <p>6、客户服务</p>
              <p>如今，许多公司需要客户服务，所以客服还是十大缺工行业之一。主要职责是负责接听和处理顾客电话。这也是一种不需要投资并适合在家做
                的工作，报酬往往也较高。</p>
              <p>7、手工艺品</p>
              <p>如果不喜欢数据录入和计算等枯燥工作，可以考虑在家制作手工艺品，然后放到网上出售。这也是目前国外比较流行的网上赚钱方式。</p>
              <p>8、写博客和文章</p>
              <p>博客目前很流行。除了自己构建博客赚钱以外，还有许多公司愿意花钱为自己的网站花钱买文章。如果你擅长写作，这也是一个很好的选择。</p>
              <p>9、微商</p>
              <p>这个就不用说了。自从微信火起来以后，微商也随之应运而生，靠微商赚钱的大有人在，不少还是女同胞们。</p>
              <p>10、网络直播</p>
              <p>这是15年到现在比较火的一种方式。最初是一些游戏>直播平台上一些游戏主播火起来。而后网络上出了一些直播平台，不少漂亮女孩儿都打
                扮的漂漂亮亮进行网络直播，一晚上收入几万的大有人在，也催生出了不少网络红人。</p>
              <p>还有一些适合在家做的工作，推荐如下，源自知乎的推荐</p>
              <p>1. 写文章，写小说; 需要文学方面的能力</p>
              <p>2. 做编剧，写剧本; 有点同上;</p>
              <p>3. 各种基于电脑的设计工作，平面设计?网站设计?</p>
              <p>4. 微信公众号运营、微博运营，需要够八卦，能抖机灵，抓热点，写段子;</p>
              <p>5. 做码农; 现在网上有很多此类的外包工作，如果你能编程，我可以给你介绍个;</p>
              <p>6. app开发 这个需要有技术</p>
              <p>7. SEO优化 这个也需要一定技术基础 不少站长就是做这个的。</p>
              <p>8. 网站编辑，运营支持;</p>
              <p>9. 数据分析，写行业报告</p>
              <p>温馨提示大家，在找合适的兼职的时候，一定要注意安全，和自己能力范围内，不要被欺骗，现在互联网骗子那么多。</p>
            </div>
          </div>
        </div>
        <div class="card">
          <img src="../assets/home/new2.png" alt="" />
          <div>
            <div class="card-title">【转载】2亿人参与“零工经济” 灵活用工平台筑起“就业蓄水池”</div>
            <div class="card-sub-title">2022-03-31 12:18</div>
            <div class="card-desc">
              <p>来源：《中国新闻》报</p>

              <p>单身妈妈赚到奶粉钱 大学生成剧本杀主持人</p>

              <p>灵活用工平台筑起“就业蓄水池”</p>

              <p>【本报作者魏寒冰 谭雨果报道】时间灵活、收入不错、职业新奇……近年来，越来越多人做起文案写手、网络主播、视频UP主、外卖骑手、网约车司机，撑起了“零工经济”新业态。据国家统计局数据，中国灵活就业人员已经达到2亿人，灵活用工时代已经到来。</p>

              <p>新经济蓬勃发展，催生出新型就业机会，也悄然改变着劳动合作关系。特别是疫情之后，以长期雇佣为特征的传统人力体系，已无法满足企业在线用工、短期用工等需求，中小企业对灵活用工的需求陡然增加。于是，一些互联网灵活用工平台应运而生，在灵活就业者与企业之间搭起一座“供需之桥”，构筑中国稳就业的“蓄水池”。</p>


              <p>2021年11月5日，新疆乌鲁木齐市高新技术产业开发区(新市区)直播电商创业园，一名主播通过网络直播，销售当地特色农产品。中新社/刘新 摄</p>

              <p>有人解决生计问题 有人找到职业方向</p>

              <p>“下班很早，无事可做，不想沉迷于打游戏，就找了一份兼职。” 小张是一位“95后”单身青年。身为一家国企的物业人员，他在晚上还有另一份职业——茶饮店兼职店员，工作时间从18时到23时。</p>

              <p>生活虽然繁忙，但小张却觉得兼职很有意思，因为能接触到很多顾客，平时内向的他变得更加开朗，还提高了社交能力。</p>

              <p>小张这份兼职是在青团社找到的，这是一家位于浙江杭州的头部灵活用工招聘平台，目前注册用户已超4300万，单日报名人次超85万，累计为超5亿人次提供过灵活就业机会。</p>

              <p>大学生、宝妈、自由职业者、在职白领和蓝领都是这个平台的主要用户。</p>

              <p>“中国的灵活用工有自身独特的发展情况，而很多政策创新、制度创新需要我们自己来探索和完善。”青团社首席运营官莫凡对《中国新闻》报表示。</p>

              <p>根据青团社的用户调研，在灵活用工群体分类中，全职宝妈是主要人群之一，目的多为赚取奶粉钱、接触社会。“尤其是离异宝妈，孩子和家庭吃穿花费最起码每月5000元人民币，还要接送小孩上下学，很难做全职工作。而通过灵活就业，上午去餐饮店做工，下午或晚上做语音主播或者带货主播，一个月可以拿到7000元左右，虽然辛苦，但很好地解决了离异宝妈的生计问题”。</p>

              <p>“每月都有房贷车贷要还。”白领孙先生坦言，找兼职是因为家庭经济压力比较大。在近期兼职中，他拿到了1万多元的收入。因为累计时间长，通过平台上的奖励机制，他的时薪还有所提升。</p>

              <p>除了补贴生计，发现自我、寻找职业方向也是年轻人选择灵活就业的诉求。小王大学毕业后，在一家咖啡连锁店找了兼职工作，由于工作表现出色，后来慢慢成长为副店长。</p>

              <p>在莫凡看来，年轻人群的就业观念发生了很大变化，“我们父母可能一辈子只做一份工作，现在大家更换工作频率比较高，很多大学生一毕业就做自由职业”。</p>

              <p>越来越多的商家选择以“零工”替代全职工</p>

              <p>从聚焦学生需求到覆盖全部人群，青团社服务目标对象的转变，折射出中国劳动力市场的变迁。</p>

              <p>青团社成立于2013年，创始人当时看到学生和校园人群通过兼职或社会实践，增长能力阅历，对未来就业也有很好的助力作用，“所以青团社开始的服务人群‘偏点状’，以学生为主。后来，随着中国经济大环境的发展变化，我们把目标服务人群扩大为‘全人群’”。</p>

              <p>莫凡介绍，“如今，人力成本是企业经营过程之中成本最高的项目之一。劳动力价格的上涨，使得企业要更加考虑精细化的运营和开源节流”。</p>

              <p>从2020年起，疫情对各行各业的影响逐步显现。对众多中小企业而言，要应对冲击实属不易，为了降低风险，很多商家会更多地选择使用零工，而非全职工。</p>

              <p>“我们在多渠道帮助劳动者实现灵活就业的同时，也致力于帮助企业扩大灵活用工比例，提升经营效率，和抗风险能力。青团社已累计服务超过70万家企业。”在莫凡看来，每个行业对于招聘速度、人员质量、管理效率、考勤排班，以及用工保险的要求都不尽相同，要从“痛点”下手，才能帮助企业更好地解决问题。</p>

              <p>青团社曾为某大型连锁茶饮店提供过灵活用工方案。“以前其用工方式比较粗放，就像公司雇白领一样，一天8个小时全职。但餐饮茶饮是有波峰波谷的，中餐晚餐时段是波峰，其他时间是波谷，如果用全职员工效率非常低。而用零工重新去招聘和排班，在峰值时间安排更多的人，用工成本就会降低。”莫凡说。</p>

              <p>莫凡表示，这些企业灵活用工，会大大降低用工成本，最终给与灵活就业者的时薪和福利待遇反而会有所提升，最终达到一个良性双赢的结果。</p>


              <p>2021年12月2日，青团社进行兼职用户调研和访谈工作。《中国新闻》报发/青团社供图</p>

              <p>从“就业蓄水池”到“就业加油站”</p>

              <p>当前，灵活就业的内涵正逐渐丰富。新媒体主播、捏脸师、宠物烘焙师、汉服造型师等新职业的出现，丰富了“打工人”的就业选择。</p>

              <p>小刘平时喜欢玩剧本杀。在大学假期期间，他开始兼职做剧本杀主持人，“这个工作不仅需要语言表达，也需要表演、串场、掌握节奏，慢慢自己的性格也发生了变化”。通过对行业的深入了解，小刘在大学毕业后也从兼职变成了全职。</p>

              <p>灵活用工平台既是“就业蓄水池”，也是“就业加油站”。在青团社用户中，有五成左右是“95后”，莫凡希望通过青团社的培训、就业服务体系，帮助年轻人尽可能拓展个人能力边界，实现人生的N种可能。“比如，电子竞技和直播带货的技能培训，是我们比较重点的培训项目，属于人社部认定的新的职业。”</p>

              <p>为了增加就业“砝码”，青团社的培训课程很“抢手”，每天约有2万人报名，包括PS(图像处理软件)、新能源行业蓝领、编程、花艺师、剧本杀写作、碳排放管理师等新技能培训。</p>

              <p>为灵活就业者系上“安全带”</p>

              <p>提到灵活就业者最担心的问题，莫凡不假思索的回答——安全可靠性。其次是薪资问题，薪资多少、能否快速结算等。</p>

              <p>灵活用工正朝着市场化、专业化、数字化方向发展。“用户会在平台留下求职意愿和自己掌握的技能，平台可以做高效的推荐和匹配，这是很典型的大数据推荐和匹配的算法。”莫凡说。</p>

              <p>新业态带来了新职业和新型劳动关系，在政策层面也看到更多积极变化。《“十四五”数字经济发展规划》提出，将进一步健全灵活就业人员参加社会保险制度和劳动者权益保障制度，推进他们参加住房公积金制度试点，逐步为灵活就业者系上“安全带”。</p>

              <p>莫凡认为，“随着国家对于灵活就业人群的保障制度及相关措施的逐步完善，以智能技术为引擎、不断突破创新的灵活就业体系，必将更健康、更完善、更具适用性，成为中国稳就业的重要‘蓄水池’”。</p>
            </div>
          </div>
        </div>
        <div class="card">
          <img src="../assets/home/new3.png" alt="" />
          <div>
            <div class="card-title">【新闻】大连市开展劳工派遣和灵活用工人员安全管理“百日攻坚”行动</div>
            <div class="card-sub-title">2022-08-11 18:48</div>
            <div class="card-desc">
              <p>大连安全生产及应急管理重点工作任务之八：</p>

              <p>全市开展劳工派遣和灵活用工人员安全管理“百日攻坚”专项整治行动</p>


              <p>按：8月2日，大连市应急管理局组织召开千人参加的全市应急管理工作视频会议，深入学习贯彻习近平总书记关于安全生产和防汛减灾的重要指示批示精神和李克强总理批示要求，贯彻落实党中央、国务院、省委省政府、市委市政府关于安全生产工作的决策部署，分析研判我市当前安全生产形势，安排部署下一阶段安全生产和应急管理重点工作。</p>

              <p>为有效防范安全风险、消除事故隐患，杜绝因劳工派遣和灵活用工环节不安全导致生产安全事故发生，按照《关于强化工作调度全力推进国家“十五条”举措和我市“66项”任务落实和各专项整治工作的通知》要求，大连市安委办在全市范围内组织开展劳工派遣和灵活用工人员安全管理“百日攻坚”专项整治行动。</p>

              <p>“百日攻坚”专项整治行动重点任务是：通过“百日攻坚”专项整治行动，对各行业领域的劳工派遣和灵活用工现状进行大排查、大起底，有针对性地建立起管理科学、规范，权责清晰的全链条安全监管，实现劳工派遣和灵活用工“十个有”。</p>

              <p>·有管理制度</p>

              <p>各地区和生产经营单位要建立劳工派遣和灵活用工人员安全管理制度，各生产经营单位要将劳工派遣和灵活用工人员全部纳入本单位统一管理。</p>

              <p>·有管理台账</p>

              <p>各生产经营单位要建立劳工派遣和灵活用工人员安全管理台账和安全管理情况的相关工作记录，并定期更新相关信息。</p>

              <p>·有培训</p>

              <p>各生产经营单位要按照规定对劳工派遣和灵活用工人员全面落实三级安全教育，高危行业劳工派遣和灵活用工人员培训不少于72学时，一般行业不少于24学时。</p>

              <p>·有考试</p>

              <p>劳工派遣单位要依法设置安全管理机构，配齐安全管理人员，其法定代表人、现场负责人及安全管理人员必须经过培训，考核合格后上岗。劳工派遣和灵活用工人员要经培训和考试合格后方可上岗作业，其培训开始资料应存档，凡是进行新的生产任务，生产经营单位要对从业人员重新进行转岗安全教育，考试合格后方可上岗作业。</p>

              <p>·有演练</p>

              <p>各生产经营单位要按照要求，定期组织劳工派遣和灵活用工人员进行应急演练，要让劳工派遣和灵活用工人员熟悉应急处置措施，熟知应急处置程序，演练后要对演练情况进行评估，对存的问题现场讲评，告知演练人员。</p>

              <p>·有安全风险告知</p>

              <p>生产经营单位要开展安全生产标准化达标和双重预防机制，将风险和安全隐患情况告知劳工派遣和灵活用工人员。</p>

              <p>·有岗位责任</p>

              <p>生产经营单位要建立全员安全生产责任制，根据各岗位实际制定安全职责，劳工派遣和灵活用工人员要熟悉本岗位安全职责，生产经营单位要制定岗位职责卡，员工要随身携带。</p>

              <p>·有上岗证书</p>

              <p>劳工派遣和灵活用工人员的特种作业人员要经专门的安全生产知识培训和考试合格，并持有有效的特种作业人员操作证。</p>

              <p>·有保障</p>

              <p>生产经营单位要按照国家规定对劳工派遣和灵活用工人员提供符合国家标准或者行业标准的劳动防护用具；按照规定为劳工派遣和灵活用工人员缴纳医疗保险和工伤保险，从事高危行业的还应按相关规定交纳安全生产责任险；严格落实工作时间和休息休假制度，防止劳工派遣和灵活用工人员因过度劳动引发生产安全事故。</p>

              <p>·有作业规范</p>

              <p>生产经营单位要建立健全作业规范，新建、改建项目和工艺更新时，要及时更新作业规范，并将新规范、新技术利用班前会、技术交底等时机向涉及的人员进行讲解并考试，合格后方可进行上岗作业，对重要岗位要将作业规范置于醒目位置。</p>

              <p>此次专项整治行动按照企业自查自改、政府部门检查以及市安委办督导核查等方式分阶段开展。市安委办要求，各地区、各部门要按照任务分工，认真谋划部署，结合所监管的企业数量、类型分布、力量配备等，细化工作任务、标准和措施，统筹、分类实施，逐企推动，将此次专项整治行动落细落实，实现劳工派遣和灵活用工人员科学、规范管理。</p>

              <p>信源：大连市应急管理局</p>
            </div>
          </div>
        </div>
      </div>
      <button class="button primary round mt60 hover-shdow">查看更多</button>
    </div>
    <!-- 最新资讯 end-->
    <!-- 使用评价 -->
<!--    <div class="title fade-in">使用者评价</div>-->
<!--    <div class="appraise fade-in">-->
<!--      <swiper :options="appraiseSwiperOptions">-->
<!--        <swiper-slide>-->
<!--          <div class="photo"><img src="../assets/home/photo.png" alt="" /></div>-->
<!--          <div class="content">-->
<!--            <p class="name">郑少华0202：</p>-->
<!--            <p class="desc">-->
<!--              这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受。-->
<!--            </p>-->
<!--          </div>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide>-->
<!--          <div class="photo"><img src="../assets/home/photo.png" alt="" /></div>-->
<!--          <div class="content">-->
<!--            <p class="name">郑少华0202：</p>-->
<!--            <p class="desc">-->
<!--              这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受，这里是用户使用过后的感受。-->
<!--            </p>-->
<!--          </div>-->
<!--        </swiper-slide>-->
<!--        <div-->
<!--          class="swiper-pagination appraise-swiper-pagination"-->
<!--          slot="pagination"-->
<!--        ></div>-->
<!--        <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--        <div class="swiper-button-next" slot="button-next"></div>-->
<!--      </swiper>-->
<!--    </div>-->
    <!-- 使用评价 end -->

    <!-- 快速导航 -->
    <div class="fast-track fade-in">
      <div class="nav-card">
        <p class="nav-title">兼职招募</p>
        <img src="../assets/home/jzzm.png" alt="" />
      </div>
      <div class="nav-card">
        <p class="nav-title">建议反馈</p>
        <img src="../assets/home/jyfk.png" alt="" />
      </div>
      <div class="nav-card">
        <p class="nav-title">快速找工</p>
        <img src="../assets/home/kszg.png" alt="" />
      </div>
      <div class="nav-card">
        <p class="nav-title">企业合作</p>
        <img src="../assets/home/qyhz.png" alt="" />
      </div>
      <div class="nav-card">
        <p class="nav-title">加入我们</p>
        <img src="../assets/home/jrwm.png" alt="" />
      </div>
    </div>
    <!-- 快速导航end -->

    <!-- 合作生态 -->
<!--    <div class="title fade-in">合作生态</div>-->
<!--    <div class="sub-title fade-in">-->
<!--      积极拓与生态伙伴合作，赋能生态伙伴，打造更完整、更强大的配送生态-->
<!--    </div>-->
<!--    <div class="logo-list fade-in">-->
<!--      <div><img src="../assets/home/logo1.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo3.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo2.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo4.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo5.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo6.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo3.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo4.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo2.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo1.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo6.png" alt="" /></div>-->
<!--      <div><img src="../assets/home/logo5.png" alt="" /></div>-->
<!--    </div>-->
    <!-- 合作生态end -->

    <!-- 联系我们 -->
    <div class="title fade-in">联系我们</div>
    <div class="sub-title fade-in">
      留下您的联系方式和意见，我们的工作人员会主动联系您的
    </div>
    <div class="form fade-in">
      <form>
        <div class="left">
          <div class="form-item">
            <div class="label">姓名：</div>
            <input id="name" name="name" v-model="form.name" type="text" />
          </div>
          <div class="form-item">
            <div class="label" for="mobile">电话：</div>
            <input
              id="mobile"
              name="mobile"
              v-model="form.mobile"
              type="text"
            />
          </div>
          <div class="form-item">
            <div class="label" for="email">邮件：</div>
            <input id="email" name="email" v-model="form.email" type="text" />
          </div>
        </div>
        <div class="right">
          <div class="label">留言：</div>
          <textarea name="message" v-model="form.message" id=""></textarea>
        </div>
      </form>
      <button class="button primary round hover-shdow" @click="submit">
        提交
      </button>
    </div>
    <!-- 联系我们end -->
    <div class="footer-banner">
      <div class="context">
        <img src="../assets/home/phone.png" alt="" />
        <div class="right-box">
          <p class="footer-title">即刻体验新业态灵活用工平台</p>
          <p class="footer-sub-title">
            在Appstore或手机应用市场中搜索“新灵活业态平台”下载，或在微信中搜索“新灵活业态平台”体验小程序或关注公众号
          </p>
          <div>
            <button class="button hover-shdow">
              <i class="icon iconfont icon-xiaochengxu" />进入小程序
            </button>
            <button class="button hover-shdow">
              <i class="icon iconfont icon-xiaochengxu" />进入小程序
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "../assets/home/banner.png";
export default {
  data() {
    return {
      banner: banner,
      playing: false,
      form: {},
      typeIndex: 0,
      bannerSwiperOptions: {
        autoplay: true,
        loop: true,
        pagination: {
          clickable: true,
          el: ".swiper-pagination",
        },
      },
      caseSwiperOptions: {
        pagination: {
          clickable: true,
          el: ".case-swiper-pagination",
        },
      },
      appraiseSwiperOptions: {
        pagination: {
          clickable: true,
          el: ".appraise-swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    lineTop() {
      return this.typeIndex * 77 + 10 + "px";
    },
  },
  methods: {
    taskView(){
      this.$router.push('/task')
    },
    submit() {
      console.log(this.form);
    },
    play() {
      this.playing = true;
      this.$refs.video.play();
    },
    close() {
      this.playing = false;
      this.$refs.video.pause();
    },
    isElemVisible(el) {
      const rect = el.getBoundingClientRect();
      const elTop = rect.top + 200;
      const elBottom = rect.bottom;
      return elTop < window.innerHeight && elBottom >= 0;
    },
    hanleScroll() {
      for (let index = 0; index < this.fadeInEls.length; index++) {
        const el = this.fadeInEls[index];
        if (this.isElemVisible(el)) {
          el.style.opacity = "1";
          el.style.transform = "translate3d(0, 0, 0)";
        }
      }
    },
  },
  created() {},
  mounted() {
    this.fadeInEls = Array.from(document.getElementsByClassName("fade-in"));
    document.addEventListener("scroll", this.hanleScroll);
    setTimeout(this.hanleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.hanleScroll);
  },
};
</script>

<style lang="scss" scoped>
.icon-xiaochengxu {
  margin-right: 15px;
}
.banner {
  height: 580px;
  position: relative;
  text-align: center;
  .swiper-slide {
    background-size: cover;
  }
  ::v-deep .swiper-pagination {
    position: absolute;
    width: 100%;
    bottom: 24px;
    z-index: 1;
    text-align: center;
  }
  ::v-deep .swiper-pagination-bullet {
    cursor: pointer;
    display: inline-block;
    background: #ffffff;
    border-radius: 2px;
    width: 46px;
    height: 4px;
    margin: 0 7px;
  }
  ::v-deep .swiper-pagination-bullet-active {
    background: var(--blue);
  }
}
.banner-title {
  position: absolute;
  font-size: 54px;
  color: #ffffff;
  letter-spacing: 0;
  top: 250px;
  left: 50%;
  transform: translateX(-50%);
}
.banner-button {
  position: absolute;
  top: 350px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 13px 16px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  .icon-xiala1 {
    font-size: 16px;
    margin-left: 15px;
  }
  .qrcode {
    transition: all ease-out 0.3s;
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    top: 52px;
    opacity: 0;
    // display: none;
  }
  &:hover {
    .qrcode {
      opacity: 1;
    }
  }
}
.title {
  padding-top: 80px;
  font-size: 30px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
}
.sub-title {
  font-size: 16px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
  margin-top: 16px;
}
.pro-card {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.pro-item {
  width: 33.333%;
  text-align: center;
  margin-top: 75px;
  img {
    display: inline-block;
    width: 120px;
    height: 120px;
    margin-bottom: 44px;
  }
}
.pro-title {
  font-size: 22px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  margin-bottom: 10px;
}
.pro-sub-title {
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  width: 257px;
  margin: 0 auto;
}
.video {
  position: relative;
  overflow: hidden;
  height: 500px;
  text-align: center;
  line-height: 500px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .player {
    height: 500px;
    width: 900px;
  }
  .close {
    width: 30px;
    margin: 10px;
  }
  .icon-bofang {
    z-index: 1;
    font-size: 100px;
    color: #fff;
    opacity: 0.8;
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  &:hover {
    .bg {
      transform: scale(1.1, 1.1);
      filter: blur(2px);
    }
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../assets/home/vadio.png");
    background-size: cover;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all ease-out 0.3s;
    background-repeat: no-repeat;
  }
}

.case {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  align-content: center;
  justify-content: space-between;
  margin-top: 60px;
  .sider-bar {
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    padding: 10px 80px 10px 0;
    border-right: 1px solid #cbcbcb;
    margin-right: 30px;
    position: relative;
    & > div {
      cursor: pointer;
      margin-bottom: 42px;
      //   padding-right: 80px;
      transition: color ease 0.3s;
      &:last-child {
        margin-bottom: 0;
      }
    }
    i {
      transition: color ease 0.3s;
      font-size: 24px;
      margin-right: 20px;
      color: #9c9c9c;
      vertical-align: middle;
    }
    .active {
      color: var(--blue);

      //   border-right: 4px solid var(--blue);
      i {
        color: var(--blue);
      }
    }
    .line {
      display: block;
      width: 4px;
      height: 35px;
      position: absolute;
      background: var(--blue);
      right: 0;
      transition: all ease 0.3s;
    }
  }
  .case-context {
    width: 889px;
    background: #ffffff;
    box-shadow: 0 2px 14px 0 rgba(209, 209, 209, 0.5);
    // padding: 60px 40px 120px 40px;
    padding: 60px 40px 0 40px;
    .swiper-slide {
      display: flex;
      justify-content: space-between;
    }
    .line {
      width: 1px;
      background: #efefef;
      margin-bottom: 120px;
    }
  }

  ::v-deep .swiper-slide {
    display: flex;
  }
  //   .left {
  //     width: 50%;
  //     border-right: 1px solid #efefef;
  //   }
  .header {
    display: flex;
    .icon-complete {
      width: 41px;
      height: 41px;
      margin-right: 20px;
    }
    .case-title {
      font-size: 15px;
      color: #333333;
      letter-spacing: 0;
      line-height: 15px;
      text-align: left;
    }
    .case-sub-title {
      font-size: 12px;
      color: #999999;
      letter-spacing: 0;
      line-height: 12px;
      margin-top: 10px;
      text-align: left;
    }
  }
  .context {
    margin-top: 42px;
    text-align: left;
  }
  .label {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    line-height: 12px;
  }
  .money {
    font-size: 17px;
    color: #333333;
    letter-spacing: 0;
    line-height: 12px;
  }
  .no {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    line-height: 12px;
  }
  .desc {
    width: 266px;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
  }
}
.case-swiper {
  height: 100%;
}

.news {
  background: #eceff2;
  padding-bottom: 58px;
  .context {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
    justify-content: space-around;
  }
  .card {
    // width: 33.33%;
    background: #fff;
    img {
      width: 100%;
      height: 240px;
      background: #9c9c9c;
    }
    & > div {
      padding: 36px 20px 10px 20px;
    }
  }
  .card-title {
    font-size: 22px;
    color: #333333;
    letter-spacing: 0;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 330px;
    text-align: left;
  }
  .card-sub-title {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    padding: 12px 0;
  }
  .card-desc {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    width: 333px;
    height: 115px;
    overflow-y: scroll;
    //overflow: hidden;
  }
}
.appraise {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  margin-top: 70px;
  ::v-deep.swiper-slide {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-content: center;
    padding-bottom: 130px;
  }
  .swiper-button-prev {
    position: absolute;
    font-family: "iconfont" !important;
    font-size: 62px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    color: #eceff2;
    top: 95px;
    left: 0;
    z-index: 99;
    &::before {
      content: "\e60e";
    }
  }
  .swiper-button-next {
    position: absolute;
    font-family: "iconfont" !important;
    font-size: 62px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    color: #eceff2;
    top: 95px;
    right: 0;
    z-index: 99;
    transform: rotateY(180deg);
    &::before {
      content: "\e60e";
    }
  }
  .swiper-button-disabled {
    display: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .photo {
    width: 224px;
    height: 258px;
    margin-right: 60px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    // line-height: 16px;
  }
  .desc {
    width: 750px;
    height: 140px;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 36px;
    overflow: hidden;
  }
}
.fast-track {
  display: flex;
}
.nav-card {
  position: relative;
  width: 20%;
  height: 20vw;
  background-size: cover;
  // background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  img {
    position: absolute;
    width: 101%;
    height: 100%;
    transition: all ease-out 0.3s;
  }
  &::after {
    content: "";
    display: block;
    width: 30px;
    height: 4px;
    border-radius: 2px;
    border-radius: 2px;
    background: var(--blue);
    z-index: 99;
  }
  &:hover {
    img {
      transform: scale(1.25, 1.25);
    }
    //
  }
}
.nav-title {
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  z-index: 99;
}

.logo-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  & > div {
    width: 25%;
    margin-bottom: 64px;
  }
  img {
    width: 216px;
    height: 96px;
  }
}
.form {
  margin-top: 80px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  form {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  }
  .form-item {
    width: 300px;
    height: 60px;
    background: #f3f6f9;
    border: 1px solid #c3cad2;
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    padding: 0 30px;
    &:last-child {
      margin-bottom: 0;
    }

    input {
      display: inline-block;
      border: 0;
      background: none;
      height: 30px;
      line-height: 30px;
      padding: 0;
      width: 150px;
    }
  }
  .label {
    font-size: 18px;
    color: #4c6478;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0;
    width: 64px;
  }
  .right {
    width: 840px;
    height: 300px;
    background: #f3f6f9;
    border: 1px solid #c3cad2;
    margin-left: 40px;
    padding: 20px 30px;
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      background: none;
      padding: 10px;
      resize: none;
    }
  }
}
.footer-banner {
  background: url("../assets/home/phone_bg.png");
  background-size: cover;
  overflow: hidden;

  & > div {
    max-width: 1200px;
    margin: 0 auto;
  }
  img {
    width: 557px;
    height: 600px;
    margin-bottom: -58px;
    margin-top: 130px;
  }
  .context {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right-box {
    margin-left: 86px;
    padding-top: 50px;
    text-align: left;
    p {
      margin: 0;
    }
  }
  .footer-title {
    font-size: 36px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: justify;
    padding-bottom: 8px;
  }
  .footer-sub-title {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    width: 513px;
    padding-bottom: 43px;
  }
  .button {
    padding: 14px 20px;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-radius: 6px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    .icon-xiaochengxu {
      font-size: 20px;
    }
  }
}
.fade-in {
  opacity: 0;
  transition: all ease-out 0.3s;
  // transform: scale(0.8);
  transform: translate3d(0, 10%, 0);
}
.mt60 {
  margin-top: 60px;
}
.mb60 {
  margin-bottom: 60px;
}


.card-desc{padding: 10px;overflow-y: scroll;}
.card-desc::-webkit-scrollbar{width:4px;}
.card-desc::-webkit-scrollbar-track{background-color:#E5E5E5;border-radius: 10px;}
.card-desc::-webkit-scrollbar-thumb{background-color:#3A3A3A;border-radius: 10px;}
.card-desc::-webkit-scrollbar-thumb:hover {background-color:#5FE3FF}
.card-desc::-webkit-scrollbar-thumb:active {background-color:#00aff0}
</style>